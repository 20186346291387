import React, { useState } from "react";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Upload,
  Space,
  Form,
  Col,
  Row,
  Select,
  Modal,
  notification
} from "antd";
import { UploadOutlined, TagOutlined, FilterOutlined, MessageOutlined, CheckOutlined,
  EyeOutlined
} from "@ant-design/icons";
import { Headers, TipoUsuario } from 'enums';
import { LoginService } from 'services/LoginService';
import ImgCrop from "antd-img-crop";
import { Etiqueta } from "enums/comunicaciones-cultura/Etiqueta";
import { Filtro } from "enums/comunicaciones-cultura/Filtro";
import { PublicacionService } from "services/comunicacion-cultura/PublicacionService";
import { useHistory } from "react-router-dom";
const { TextArea } = Input;
const { Text, Title } = Typography;


const FormCrearPublicacion = () => {
  const [formPublicacion] = Form.useForm();
  // const [notification, setNotification] = useState(false);
  const [previewLinks, setPreviewLinks] = useState(false);
  const [fileList, setFileList] = useState([]);
	const [uidList, setUidList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
  const [texto, setTexto] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const datos = {
		uid: texto,
	};

  const props = {
		name: "file",
		multiple: true,
		action: process.env.REACT_APP_ENDPOINT + "comunicaciones-cultura/cargarDocumentos",
		headers: {
			// Authorization: "Bearer " + LoginService.getJwtToken(),
			// [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			// [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			// [Headers.TIPO_USUARIO]: "ADMIN"
      ["authorization"]: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
      [Headers.TIPO_USUARIO]: TipoUsuario.ADMIN
		},
		data: datos,
		beforeUpload(file) {
      console.log("PPPPP::", file);
			setTexto(file.uid);
			return file;
		},
	};

  const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
	};

  const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

  function handleOnClickCancelar() {
		// history.push("/personal/trabajador");
	}

  const handleCancel = () => setPreviewVisible(false);

  const onClickBtnCrearPublicacion= async(form) =>{
    try{
      setLoading(true);
      let usuarioRegistro = {
        nombreCompleto:LoginService.getUsuarioAutenticado().nombreCompleto,
        numeroDocumentoIdentidad:LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
      }

      let filtroMapeado = {
        tipo:form.filtro,
        dato:null
      }
      form.filtro = filtroMapeado;
      form.usuarioRegistro = usuarioRegistro;
      form.imagenes = fileList.map((c) => c.uid)

      console.log("ff____:",form);
      
      // // form.horaInicio = moment(form.horaInicioAuxiliar).format('HH:mm');
      // // form.horaFin = moment(form.horaFinAuxiliar).format('HH:mm');
      // form.usuarioRegistro = usuarioRegistro;
      // console.log("horarios", form);
      const respuesta = await PublicacionService.insertar(form);     
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el horario exitosamente",
      });
       setLoading(false);
       history.push(`/comunicaciones-cultura/publicaciones`);
    } catch (error) {
       setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrio un error al registrar la publicación",
      });
    }
  }

  return (
    <Form
      layout="vertical"
      size="medium"
      form={formPublicacion}
      onFinish={onClickBtnCrearPublicacion}
    >
      <Form.Item
        label={
          <span>
            <MessageOutlined style={{ marginRight: 5 }} />
            Escribir Mensaje
          </span>
        }
        name="mensaje"
        rules={[
          {
            required: true,
            message: "El campo Mensaje es obligatorio.",
          },
        ]}
      >
        <TextArea
        rows={4}
        placeholder="Escribe algo..."
      />
      </Form.Item>
      <Row gutter={16}  style={{ marginTop: 0 }}>
        <Col span={12}>
          <Form.Item
            label={
              <span>
                <TagOutlined style={{ marginRight: 5 }} />
                Agregar Etiqueta
              </span>
            }
            name="etiqueta"
            rules={[
              {
                required: true,
                message: "El campo Etiqueta es obligatorio.",
              },
            ]}
          >
            {/* <Select
              placeholder="Seleccione etiqueta"
              //onChange={onChangeTipoDocumento}
            >
              <Select.Option value={Etiqueta.COMUNICADO.id}>
                {Etiqueta.COMUNICADO.nombre}
              </Select.Option>
              <Select.Option value={Etiqueta.PUBLICACION.id}>
                {Etiqueta.PUBLICACION.nombre}
              </Select.Option>
            </Select> */}
            <Input placeholder="Ingrese etiqueta"></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
            label={
              <span>
                <FilterOutlined style={{ marginRight: 5 }} />
                Filtrar
              </span>
            }
            name="filtro"
            rules={[
              {
                required: true,
                message: "El campo Filtrar es obligatorio.",
              },
            ]}
          >
            <Select
              placeholder="Seleccione filtro"
              //onChange={onChangeTipoDocumento}
            >
              <Select.Option value={Filtro.COLABORADOR}>
                {Filtro.COLABORADOR}
              </Select.Option>
              <Select.Option value={Filtro.CARGO}>
                {Filtro.CARGO}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Form.Item
          label={
            <span style={{ marginLeft: 10 }}>
              <UploadOutlined style={{ marginRight: 5 }} />
              Agregar Imagen
            </span>
          }
        >
          {/* <div style={{ marginLeft: "12px" }}>
            <ImgCrop rotate minZoom={0.5} aspect={2 / 1} cropperProps={{ restrictPosition: false }} grid>
              <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                accept={undefined}
              >
                {"+ Subir"}
              </Upload>
            </ImgCrop>
          </div> */}
          <div style={{ marginLeft: "12px" }}>
            <ImgCrop 
              rotationSlider
              aspectSlider 
              minZoom={0.5} 
              aspect={2 / 1} 
              cropperProps={{ restrictPosition: false }} 
              quality={1}
              showGrid
            >
              <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                accept={undefined}
              >
                {"+ Subir"}
              </Upload>
            </ImgCrop>
            <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} destroyOnClose>
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>

        </Form.Item>
       </Row>
      {/* Checkboxes */}
      {/* <div style={{ marginBottom: "16px" }}>
        <Checkbox
          checked={notification}
          onChange={(e) => setNotification(e.target.checked)}
        >
          Enviar notificación push
        </Checkbox>
        <Checkbox
          style={{ marginLeft: "16px" }}
          checked={previewLinks}
          onChange={(e) => setPreviewLinks(e.target.checked)}
        >
          Previsualizar links
        </Checkbox>
      </div> */}
      <Row justify="center">
						<Space>
							<Col>
								<Form.Item style={{ textAlign: "center" }}>
									<Button type="default"  icon={<EyeOutlined />} onClick={handleOnClickCancelar}>
									  Vista Previa
									</Button>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item style={{ textAlign: "center" }}>
									<Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                  Publicar
									</Button>
								</Form.Item>
							</Col>
						</Space>
					</Row>
    </Form>
  );
};
export default FormCrearPublicacion;
