import { useAtom } from "jotai";
import { colaboradoresAtom, fechaSeleccionadaAtom, formInstanceAtom, paginaAtom, totalColaboradoresAtom } from "../store/TabCalendarioStore";
import { SolicitudAusenciaService, VacacionesService } from "services/VacacionesService";

const useCalendario = () => {
    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);
    const [fechaSeleccionada, setFechaSeleccionada] = useAtom(fechaSeleccionadaAtom);
    const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);
    const [totalColaboradores, setTotalColaboradores] = useAtom(totalColaboradoresAtom);
    const [pagina, setPagina] = useAtom(paginaAtom);

    const onClickBtnMostrarCalendario = () => {
        formInstance.validateFields()
            .then(async (formulario) => {
                fetchSolicitudesPorColaborador(formulario.periodo, 0);
                setFechaSeleccionada(formulario.periodo);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }

    const loadMoreColaboradores = async () => {
        fetchSolicitudesPorColaborador(fechaSeleccionada, pagina + 1);
    }

    const fetchSolicitudesPorColaborador = async (fecha, pagina) => {
        const response = await SolicitudAusenciaService.getCalendario({
            mes: fecha.month() + 1,
            anio: fecha.year(),
            pagina: pagina,
            cantidad: 17
        });
        if (pagina === 0) {
            setColaboradores(response.colaboradores);
        } else {
            setColaboradores([...colaboradores, ...response.colaboradores]);
        }
        setTotalColaboradores(response.totalColaboradores);
        setPagina(pagina);
    }

    const getDias = () => {
        return Array.from({ length: fechaSeleccionada.daysInMonth() }, (_, index) => index + 1);
    }

    return {
        pagina,
        colaboradores,
        totalColaboradores,
        formInstance,
        fechaSeleccionada,
        loadMoreColaboradores,
        setPagina,
        getDias,
        setFormInstance,
        onClickBtnMostrarCalendario
    };
};

export default useCalendario;
