import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.BASE;

const PublicacionService = {
  async insertar(publicacion) {
    const respuesta = await ApiService(URL_API).post("/comunicaciones-cultura/publicaciones", publicacion);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/comunicaciones-cultura/publicaciones");
    return respuesta.data;
  }
};

export { PublicacionService };
